import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from '@optimo/core';
import { environment } from '../../../../environments/environment';
import { SEOService } from '../../services/SEO.service';
import { Router } from '@angular/router';
import { isUz, MENUTREE, phoneNumber } from '../../../../config/ui-config';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  public isUz = isUz;
  public phoneNumber = phoneNumber;
  @Input()
  theme: 'light' | 'dark' = 'light';

  @Input()
  isFixed = true;

  @Input()
  landing = false;

  @Input()
  requestButtonVisibility = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private googleAnalytics: GoogleAnalyticsService,
    public translateService: TranslateService,
    private seoService: SEOService,
    private router: Router,
    private languageService: LanguageService,
  ) {}

  public dashboardLoginPageUrl: string = environment.dashboardLoginPageUrl;

  onLogin() {
    this.router.navigate(['/dashboard']);
    if (this.router.url.includes('/dashboard')) {
      this.languageService.listenRouterAndNavigateToDashboard();
    }
  }

  get getHomeRoute() {
    const isValidLanguage =
      this.translateService.currentLang === this.translateService.defaultLang;
    return isValidLanguage ? '/' : `/${this.translateService.currentLang}`;
  }
  navItems = MENUTREE;
  burgerActive = false;

  getRouterLink(item) {
    const isValidLanguage =
      this.translateService.currentLang === this.translateService.defaultLang;
    return (
      (item.url === '/' && isValidLanguage
        ? ''
        : `/${this.translateService.currentLang}`) + item.url
    );
  }
  onNavigate(item) {
    const url = this.getRouterLink(item);
    if (url == this.router.url) {
      this.burgerActive = !this.burgerActive;
    }
  }

  toggleBurger(): void {
    this.burgerActive = !this.burgerActive;
    const classNameStatus = this.burgerActive ? 'add' : 'remove';
    this.document.body.classList[classNameStatus]('modal-open');
  }

  onRequest(): void {
    this.googleAnalytics.sendEvent('request_order', 'button_click');
  }

  onRequestDemo(): void {
    this.googleAnalytics.sendEvent('request_demo', 'button_click');
  }

  trackToDashboard(): void {
    this.seoService.pushToDataLayer({
      event: 'goToDashboard',
      'event-uid': '9761f9ba-b5db-433e-a13f-3d88230141cf',
    });
    setTimeout(() => {
      this.router.navigate(['/dashboard']);
      if (this.router.url.includes('/dashboard')) {
        this.languageService.listenRouterAndNavigateToDashboard();
      }
    }, 1000);
  }

  scrollToTop(path: string) {
    if (
      (this.router.url === `/${this.translateService.currentLang}` ||
        this.router.url === '/') &&
      path === '/'
    ) {
      this.document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
}
